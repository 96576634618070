import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreUIModule } from '@myia/ngx-core-ui';
import { HttpModule } from '@myia/ngx-http';
import { LocalizationModule } from '@myia/ngx-localization';
import { ToastModule } from '@myia/ngx-toast';
import { AutoSizeDirective } from './components/autoSizeDirective';
import { CalendarIconComponent } from './components/calendarIcon';
import { ControlMessagesComponent } from './components/controlMessages';
import { InputFileComponent } from './components/controls/inputFile';
import { InputMultiSelectionComponent } from './components/controls/inputMultiSelection';
import { InputMultiSelectionItemPipe } from './components/controls/inputMultiSelectionItemPipe';
import { InputTextFieldComponent } from './components/controls/inputTextField';
import { InputTextFieldHasValuePipe } from './components/controls/inputTextFieldHasValuePipe';
import { DropEffectDirective } from './components/dropEffectDirective';
import { FloatingElementDirective } from './components/floatingElement.directive';
import { FreeDraggingDirective } from './components/freeDragging/freeDraggingDirective';
import { FreeDraggingHandleDirective } from './components/freeDragging/freeDraggingHandle';
import { DROPDOWN_DIRECTIVES } from './components/ng2-bootstrap/dropdown';
import { ReduxFeatureModule, ReduxModule, ReduxStore } from '@myia/ngx-redux';
import { TooltipModule } from '@myia/ngx-tooltip';
import { InputCheckboxComponent } from './components/controls/inputCheckbox';
import { InputDropDownComponent } from './components/controls/inputDropDown';
import { DevToolsComponent } from './components/devTools';
import { CheckWebUrlPipe } from './components/pipes/checkWebUrlPipe';
import { ItemByValuePipe } from './components/pipes/itemByValuePipe';
import { SafePipe } from './components/pipes/safePipe';
import { ToStringPipe } from './components/pipes/toStringPipe';
import { SideBarComponent } from './components/sideBar';
import { SideBarItemComponent } from './components/sideBarItem';
import { StatusBarComponent } from './components/statusBar';
import { TopBarComponent } from './components/topBar';
import { TopBarCultureSwitchComponent } from './components/topBarCultureSwitch';
import { ValidationErrorsPipe } from './components/validationErrorsPipe';
import { authReducerKey, authReducers } from './redux/authReducers';
import statusBarReducers from './redux/statusBarReducers';
import { ContextMenuDirective } from './components/context-menu/contextMenuDirective';
import { ContextMenuHolderComponent } from './components/context-menu/contextMenuHolder';
import { ScrollControlDirective } from './components/scrollControlDirective';
import { FloatingContainerBoundsDirective } from './components/floatingContainerBounds.directive';
import { FloatingElementBoundaryContainerPipe } from './components/floatingElementBoundaryContainerPipe';
import { ScrollBarDirective } from './components/scrollBarDirective';
import { SeparatorComponent } from './components/splitter/separator.component';
import { SplitterComponent } from './components/splitter/splitter.component';
import { VerticalSplitterComponent } from './components/splitter/verticalSplitter.component';
import { HorizontalSplitterComponent } from './components/splitter/horizontalSplitter.component';
import { cultureReducerKey, cultureReducers } from './redux/cultureReducers';


/* Redux configuration - must be provided as factory to be AOT compatible */
export function provideReducers() {
    return [authReducers, cultureReducers, statusBarReducers];
}
export function providePersistedReducersNames() {
    return [authReducerKey, cultureReducerKey];
}

let done = false;

@NgModule({
    imports: [
        CommonModule,
        CoreUIModule,
        FormsModule,
        HttpModule,
        LocalizationModule,
        ReactiveFormsModule,
        ReduxModule.forChild(provideReducers, providePersistedReducersNames),
        RouterModule,
        ToastModule,
        TooltipModule,
    ],
    declarations: [
        AutoSizeDirective,
        CalendarIconComponent,
        CheckWebUrlPipe,
        ContextMenuDirective,
        ContextMenuHolderComponent,
        ControlMessagesComponent,
        DevToolsComponent,
        DROPDOWN_DIRECTIVES,
        DropEffectDirective,
        FloatingContainerBoundsDirective,
        FloatingElementBoundaryContainerPipe,
        FloatingElementDirective,
        FreeDraggingDirective,
        FreeDraggingHandleDirective,
        HorizontalSplitterComponent,
        InputCheckboxComponent,
        InputDropDownComponent,
        InputFileComponent,
        InputMultiSelectionComponent,
        InputMultiSelectionItemPipe,
        InputTextFieldComponent,
        InputTextFieldHasValuePipe,
        ItemByValuePipe,
        SafePipe,
        ScrollBarDirective,
        ScrollControlDirective,
        SeparatorComponent,
        SideBarComponent,
        SideBarItemComponent,
        SplitterComponent,
        StatusBarComponent,
        TopBarComponent,
        TopBarCultureSwitchComponent,
        ToStringPipe,
        ValidationErrorsPipe,
        VerticalSplitterComponent,

    ],
    exports: [
        AutoSizeDirective,
        CalendarIconComponent,
        CheckWebUrlPipe,
        ContextMenuDirective,
        ContextMenuHolderComponent,
        ControlMessagesComponent,
        DevToolsComponent,
        DROPDOWN_DIRECTIVES,
        DropEffectDirective,
        FloatingContainerBoundsDirective,
        FloatingElementBoundaryContainerPipe,
        FloatingElementDirective,
        FreeDraggingDirective,
        FreeDraggingHandleDirective,
        HorizontalSplitterComponent,
        InputCheckboxComponent,
        InputDropDownComponent,
        InputFileComponent,
        InputMultiSelectionComponent,
        InputTextFieldComponent,
        InputTextFieldHasValuePipe,
        ItemByValuePipe,
        SafePipe,
        ScrollControlDirective,
        ScrollControlDirective,
        SeparatorComponent,
        SideBarComponent,
        SideBarItemComponent,
        SplitterComponent,
        StatusBarComponent,
        TopBarComponent,
        TopBarCultureSwitchComponent,
        ToStringPipe,
        ValidationErrorsPipe,
        ScrollBarDirective,
        VerticalSplitterComponent,
    ],
    providers: [
        // all services are singletons with @Injectable({ providedIn: 'root' })
    ],
})
export class ViewSharedComponentsModule extends ReduxFeatureModule {
    constructor(reduxStore: ReduxStore, featureInjector: Injector) {
        super(done ? null : reduxStore, featureInjector);
        done = true;
    }
}

