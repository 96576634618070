import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, HostListener, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControlOptions, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Placement } from '@floating-ui/dom';
import { getNewComponentId, Logger } from '@myia/ngx-core';
import { OnDestroyDirective } from '@myia/ngx-core-ui';
import { CultureService, LocalizationService } from '@myia/ngx-localization';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ICompleterItem } from '../../entities/completerItem.interface';
import { CompleterService } from '../../services/completerService';

// keyboard events
const KEY_DW = 40; // down
const KEY_RT = 39; // right
const KEY_UP = 38; // up
const KEY_LF = 37; // left
const KEY_ES = 27; // escape
const KEY_EN = 13; // enter
const KEY_TAB = 9; // tab

@Component({
    selector: 'input-text-field',
    styleUrls: ['./inputTextField.component.scss'],
    hostDirectives: [
        OnDestroyDirective
    ],
    template: `
        <div class="textFieldGroup"
             [ngClass]="{required: isRequired, modified: isModified, withLabel: label, hasValue: value | hasValue, init: isInitialized}">
            <input #inputEl [id]="fieldName" [attr.maxlength]="maxLength" [type]="isPassword ? 'password' : 'text'"
                   (blur)="onBlur()" (keydown)="onKeyDown($event)" (keyup)="onKeyUp($event)" (focus)="onFocus()"
                   [formControl]="control" [ngClass]="{hasValue: value | hasValue, password: isPassword}"
                   [readonly]="readonly" [tabIndex]="tabIndex"
                   [attr.autocomplete]="disableBrowserAutoComplete || isPassword || completerService ? 'off' : 'on'"
                   [attr.autocorrect]="disableBrowserAutoComplete || isPassword || completerService ? 'off' : 'on'"
                   [attr.autocapitalize]="disableBrowserAutoComplete || isPassword || completerService || autoCapitalize ? 'off' : 'on'"
                   [tooltip]="message" [tooltipPlacement]="messagePlacement" [tooltipEnable]="!value" [tooltipDelay]="0"
                   [attr.placeholder]="placeHolder"/>
            <div *ngIf="!readonly" class="reqMark line"></div>
            <div *ngIf="label" class="bar"></div>
            <label *ngIf="label">{{label}}</label>
            <div class="autoCompleteIcon" *ngIf="showListIcon && !isDisabled && !value"
                 (click)="showAutoCompleteList($event)">
                <svg-icon name="drop-down-list"></svg-icon>
            </div>
            <div *ngIf="completerOpened" class="completerDropDownBlock" [ngClass]="{withValue: value}">
                <div *ngIf="isSearching && displaySearching && !autoCompleteItems?.length"
                     class="completer-searching">{{textSearching}}</div>
                <div *ngIf="!isSearching && displayNoResults && (!autoCompleteItems?.length)"
                     class="completer-no-results">{{textNoResults}}</div>
                <progress-indicator-bar *ngIf="isSearching && displaySearching && autoCompleteItems?.length"
                                        indicatorClass="completer-searching-loader blockLoader fromLeft"></progress-indicator-bar>
                <div class="completer-row-wrapper"
                     *ngFor="let item of autoCompleteItems; let rowIndex=index; trackBy: trackCompleterItem">
                    <div class="completer-row" [ngClass]="{selected: highlightedItem === item}"
                         (click)="selectCompleterItem(item)" (mouseenter)="highlightItem(item)">
                        <div *ngIf="item.image || item.image === ''" class="completer-image-holder">
                            <img *ngIf="item.image !== ''" src="{{item.image}}" class="completer-image"/>
                            <div *ngIf="item.image === ''" class="completer-image-default"></div>
                        </div>
                        <div class="completer-item-text"
                             [ngClass]="{'completer-item-text-image': item.image || item.image === '' }">
                            <div class="title">{{item.title}}</div>
                            <div class="description" *ngIf="item.description">{{item.description}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <control-messages *ngIf="!readonly" [messages]="control|validationErrors"></control-messages>
            <button *ngIf="isPassword && value" class="togglePasswordBtn" type="button"
                    (mousedown)="inputEl.type='text'" (mouseup)="inputEl.type='password'" [disabled]="isDisabled"
                    tabindex="-1">
                <svg-icon name="eye"></svg-icon>
            </button>
        </div>
    `
})
export class InputTextFieldComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() isPassword = false;
    @Input() isModified = false;

    @Input() maxLength?: number;
    @HostBinding('class') hostClasses?: string;

    @Input() readonly = false;
    @Input() tabIndex?: string;
    @Input() validator?: ValidatorFn | null;
    @Input() label?: string;
    @Input() placeHolder?: string;
    @Input() formGroupRef?: FormGroup;
    @Input() fieldName?: string | number;
    @Input() message?: string;
    @Input() messagePlacement: Placement = 'bottom';
    @Input() disableBrowserAutoComplete = false;
    @Input() autoCapitalize = '';
    @Input() parseInputValue?: (val: string) => unknown;

    @Input() updateOn?: string;

    @Input() detectExternalChanges = false;
    @Output() valueChange = new EventEmitter<any>();
    @Output() inputBlur = new EventEmitter<void>();
    @Output() inputFocus = new EventEmitter<void>();
    isInitialized = false;

    @ViewChild('inputEl', {static: true}) inputEl?: ElementRef;

    control!: FormControl;

    // auto complete related properties
    @Input() completerService?: CompleterService;
    @Input() minSearchLength = 1;
    @Input() clearUnselected = false;
    @Input() autoMatch: string | null = null;
    @Input() showListIcon = false;
    @Output() autoCompleted = new EventEmitter<ICompleterItem>();
    autoCompleteItems?: ICompleterItem[];
    highlightedItem?: ICompleterItem;
    isSearching = false;
    completerOpened = false;
    completerEnabled = false;
    displaySearching = true;
    displayNoResults = true;

    private _textNoResults?: string;
    private _textSearching?: string;

    private _valueChangeDetectionInterval: any;
    private _disabled = false;
    private _isRequired = false;

    private _classNames?: string;
    private _value: string | null = null;
    private _destroy$ = inject(OnDestroyDirective).destroy$;

    private _controlName?: string;
    private _valueInitialized = false;

    private _hasFocus = false;
    private _isFromCompleter = false; // is set when text was set as user selection from auto complete list

    private _cancelBlurAction = false; // cancel blur action when clicked on listIcon

    get isRequired(): boolean {
        return this._isRequired;
    }

    @Input() set isRequired(val: boolean) {
        this._isRequired = val;
        this.updateValidators();
    }

    @Input() set classNames(value: string) {
        this._classNames = value;
        this.updateHostClasses();
    }

    get isDisabled(): boolean {
        return this._disabled;
    }

    @Input() set isDisabled(val: boolean) {
        this._disabled = val;
        if (this.control) {
            if (val) {
                this.control.disable();
            } else {
                this.control.enable();
            }
        }
    }

    @Input() set textSearching(text: string | undefined) {
        this._textSearching = text;
    }

    get textSearching(): string | undefined {
        return this._textSearching;
    }

    @Input() set textNoResults(text: string | undefined) {
        this._textNoResults = text;
    }

    get textNoResults(): string | undefined {
        return this._textNoResults;
    }

    get value(): any {
        return this._value;
    }

    @Input() set value(v: any) {
        const valueChanged = v !== this._value && !(this.isNaN(v) && this.isNaN(<any>this._value));
        if (valueChanged || !this._valueInitialized) {
            if (this.parseInputValue) {
                v = this.parseInputValue(v);
            }
            this._value = v;
            if (this._valueInitialized) {
                this.valueChange.emit(v);
            } else {
                this._valueInitialized = true;
            }
            if (this.control) {
                this.control.setValue(this._value);
            }
        }
    }


    constructor(private _localizationService: LocalizationService, private _cultureService: CultureService, private _logger: Logger, private _changeDetectorRef: ChangeDetectorRef) {
        // subscribe to onChange event, in case the culture changes
        this._cultureService.onChange.pipe(
            takeUntil(this._destroy$)
        ).subscribe(() => {
            // update timezone (is localized)
            this.updatedLocalizedTexts();
        });
    }

    updatedLocalizedTexts() {
        this._textNoResults = this._localizationService.translate('General.Auto_Complete_No_results');
        this._textSearching = this._localizationService.translate('General.Auto_Complete_Searching');
    }


    ngOnInit() {
        this.updatedLocalizedTexts();
        this.updateHostClasses();
        this.control = new FormControl({
            value: this._value,
            disabled: this._disabled
        }, {updateOn: this.updateOn} as AbstractControlOptions);
        this.control.valueChanges.subscribe(this.updateData.bind(this));
        if (this.formGroupRef) {
            this._controlName = this.fieldName?.toString() || getNewComponentId();
            this.formGroupRef.addControl(this._controlName, this.control);
        }
        this.updateValidators();

        if (this.completerService) {
            this.completerService.subscribe(items => {
                this.isSearching = false;
                this.autoCompleteItems = items;
                this._changeDetectorRef.markForCheck();
                //this._logger.log('Auto complete: ' + (items ? items.length : 0));
            });
            this.control.valueChanges.pipe(
                debounceTime(200),
                distinctUntilChanged()
            ).subscribe(query => {
                this.performAutoCompletion(query);
            });
        }

        if (this.textSearching === 'false') {
            this.displaySearching = false;
        }
    }

    ngOnDestroy() {
        if (this.completerService) {
            this.completerService.cancel();
        }
        this.stopValueChangeDetection();
        if (this.formGroupRef && this._controlName) {
            this.formGroupRef.removeControl(this._controlName);
        }
    }

    ngAfterViewInit() {
        if (this.detectExternalChanges) {
            this.startValueChangeDetection();
        }
    }

    updateData(newValue: any) {
        if (this.value !== newValue) {
            this.value = newValue;
            this.isInitialized = true;
        }
    }

    setFocus() {
        if (this.inputEl) {
            this.inputEl.nativeElement.focus();
        }
    }

    onFocus() {
        this._hasFocus = true;
        this.inputFocus.emit();
        this.completerEnabled = true;
    }

    onBlur() {
        this._hasFocus = false;
        this.inputBlur.emit();
    }

    onKeyUp(event: any) {
        if (event.keyCode === KEY_ES) {
            this.closeCompleter();
        }
    }

    onKeyDown(event: any) {
        this.completerEnabled = true;

        if (this.completerOpened) {
            if (event.keyCode === KEY_EN) {
                if (this.highlightedItem) {
                    event.preventDefault();
                }
                this.selectCompleterItem(this.highlightedItem);
            } else if (event.keyCode === KEY_DW) {
                event.preventDefault();
                this.highlightNextItem(1);
            } else if (event.keyCode === KEY_UP) {
                event.preventDefault();
                this.highlightNextItem(-1);
            } else if (event.keyCode === KEY_ES) {
                // This is very specific to IE10/11 #272
                // without this, IE clears the input text
                event.preventDefault();
            }
        } else {
            if (event.keyCode === KEY_DW) {
                event.preventDefault();
                if (this.completerService) {
                    this.performAutoCompletion(this._value);
                }
            }
        }
    }

    @HostListener('blur', ['$event'])
    public onComponentBlur(_: any) {
        setTimeout(
            () => {
                if (this._cancelBlurAction) {
                    this._cancelBlurAction = false;
                    return;
                }
                if (this.autoMatch && this.completerOpened && !this._isFromCompleter && this.autoCompleteItems) {
                    // auto match from listed items
                    const matchedItem = this.autoCompleteItems.find(item => this.autoMatch && item.originalObject[this.autoMatch] === this.value);
                    if (matchedItem) {
                        this.selectCompleterItem(matchedItem);
                        return;
                    }
                }
                this.closeCompleter();
                if (this.clearUnselected && !this._isFromCompleter) {
                    this.value = '';
                }
            },
            200
        );
    }

    stopValueChangeDetection() {
        if (this._valueChangeDetectionInterval) {
            clearInterval(this._valueChangeDetectionInterval);
            this._valueChangeDetectionInterval = null;
        }
    }

    startValueChangeDetection() {
        // check input value periodically to detect input value change from outside (e.g. browser password managers/plugins)
        this._valueChangeDetectionInterval = setInterval(
            () => {
                const value = this.inputEl?.nativeElement.value;
                if (this._value !== value) {
                    this._valueInitialized = true; // set this flag to enable valueChange event
                    this.updateData(value);
                }
            },
            500);
    }

    trackCompleterItem(index: number, item: ICompleterItem): any {
        return item.originalObject;
    }

    highlightItem(item: ICompleterItem) {
        this.highlightedItem = item;
    }

    selectCompleterItem(item: ICompleterItem | undefined) {
        this._isFromCompleter = true;
        this.closeCompleter();
        this.autoCompleted.emit(item);
    }

    showAutoCompleteList($event: any) {
        $event.stopPropagation();
        $event.preventDefault();
        this._cancelBlurAction = !this.completerOpened;
        if (!this.completerOpened) {
            this.performAutoCompletion('', true);
        } else {
            this.closeCompleter();
            this.setFocus();
        }
    }

    private updateHostClasses() {
        this.hostClasses = 'inputField inputTextField ' + (this._classNames || '');
    }


    private highlightNextItem(delta: number) {
        let highlightedIndex = this.highlightedItem ? this.autoCompleteItems?.findIndex(item => item.originalObject === this.highlightedItem?.originalObject) ?? -1 : -1;
        if (highlightedIndex === -1 && this.autoCompleteItems) {
            highlightedIndex = delta > 0 ? 0 : this.autoCompleteItems.length - 1;
        } else {
            highlightedIndex += delta;
        }
        if (highlightedIndex >= 0 && this.autoCompleteItems && highlightedIndex < this.autoCompleteItems.length) {
            this.highlightItem(this.autoCompleteItems[highlightedIndex]);
        }
        this._changeDetectorRef.markForCheck();
    }

    private closeCompleter() {
        this.completerEnabled = false;
        this.completerOpened = false;
        this.highlightedItem = undefined;
        this.autoCompleteItems = undefined;
        this._changeDetectorRef.markForCheck();
        window.document.removeEventListener('click', this.closeCompleter.bind(this));
    }

    private performAutoCompletion(query: string | null, force?: boolean) {
        if (this.completerEnabled || force) {
            this._isFromCompleter = false;
            if (force || (query && query.length >= this.minSearchLength)) {
                this.completerOpened = true;
                this.highlightedItem = undefined;
                this.isSearching = true;
                this._changeDetectorRef.markForCheck();
                this.completerService?.search(query);
                if (force) {
                    window.document.addEventListener('click', this.closeCompleter.bind(this));
                }
            } else {
                this.isSearching = false;
                this.completerService?.cancel();
                this.autoCompleteItems = undefined;
                this.closeCompleter();
                this._logger.log('Cancel auto complete');
            }
        }
    }

    private updateValidators() {
        if (this.control) {
            const validators = this.validator ? [this.validator] : [];
            if (this._isRequired) {
                validators.push(Validators.required);
            }
            this.control.setValidators(Validators.compose(validators));
            this.control.updateValueAndValidity();
        }
    }

    private isNaN(value: any) {
        return typeof value !== 'string' && isNaN(value);
    }
}
