import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'itemByValue'
})
export class ItemByValuePipe<TItem, TValue> implements PipeTransform {
    transform(items: ReadonlyArray<TItem> | undefined, value: TValue, valuePath: keyof TItem): TItem | undefined {
        return items ? items.find(i => (valuePath ? i[valuePath] : i) === value) : undefined;
    }
}
