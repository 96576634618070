import { AfterViewInit, Directive, ElementRef, inject, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OnDestroyDirective } from '@myia/ngx-core-ui';
import { NgResizeObserver, ngResizeObserverProvidersWithPonyfill } from 'ng-resize-observer';
import { map, takeUntil } from 'rxjs/operators';
import { FloatingElementService } from '../services/floatingElementService';

@Directive({
    selector: '[floatingContainerBounds]',
    providers: [...ngResizeObserverProvidersWithPonyfill],
    hostDirectives: [
        OnDestroyDirective
    ],
})
export class FloatingContainerBoundsDirective implements AfterViewInit, OnDestroy {
    private _destroy$ = inject(OnDestroyDirective).destroy$;
    private _routeUrl?: string;

    constructor(private _floatingElementService: FloatingElementService, private resize$: NgResizeObserver, private _elementRef: ElementRef, private _activatedRoute: ActivatedRoute) {
    }

    ngAfterViewInit() {
        this._routeUrl = this.getContainerRouteUrl();
        this._floatingElementService.registerBoundingContainer(this._routeUrl, this._elementRef.nativeElement);
        this.resize$.pipe(
            map(() => {
                this._measureRect();
            }),
            takeUntil(this._destroy$)
        ).subscribe();
        this._measureRect();
    }

    ngOnDestroy() {
        this._floatingElementService.unregisterBoundingContainer(this._routeUrl);
    }

    private _measureRect() {
        this._floatingElementService.updateBounds();
    }

    private getContainerRouteUrl() {
        return this._activatedRoute.snapshot.pathFromRoot.map(r => r.url.filter(u => !!u).join('/')).filter(u => !!u).join('/');
    }

}
