import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { Options } from 'simplebar';
import * as SimpleBar from 'simplebar/dist/simplebar';

@Directive({
    selector: '[customScrollBar]',
    exportAs: 'scrollBarDirective' //the name of the variable to access the directive using ViewChild()
})
export class ScrollBarDirective implements AfterViewInit, OnDestroy {

    @Input() customScrollBar = false;

    @Input() options?: Options;

    simpleBar?: SimpleBar;

    constructor(private _element: ElementRef) {
    }

    ngAfterViewInit(): any {
        if (this.customScrollBar) {
            // setTimeout(() => {
            //     //this._element.nativeElement.style.width = `${this._element.nativeElement.offsetWidth}px`;
            //     this.simpleBar = new SimpleBar(this._element.nativeElement, {autoHide: false,  ...(this.options ?? {})});
            //     //this._element.nativeElement.style.width = '';
            // }, 100);
        }
    }

    ngOnDestroy() {
        this.simpleBar?.unMount();
        this.simpleBar = undefined;
    }
}
