import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hasValue'
})
export class InputTextFieldHasValuePipe implements PipeTransform {
    transform(value: any): any {
        return value || value === 0; // zero number is also valid value for text box
    }
}
