import { createPersistedState, createState, rehydrateReducerState, updateState } from '@myia/ngx-redux';
import { REHYDRATE } from 'redux-persist';
import { AUTH_COMPLETED, AUTH_LOG_OUT, AUTH_REFRESH_COMPLETED, AUTH_REFRESH_FAILED, AUTH_REFRESH_STARTED } from './authActions';
import { IAuthState } from './authState.interface';

export const authReducerKey = 'auth';

const initialState = createPersistedState<IAuthState>(
    {
        _isLoading: false,
        hasError: false,
        isAuth: false,
        clientId: null,
        token: null,
        refreshToken: null,
        email: null
    });

function authReducer(state = initialState, action: any) {
    switch (action.type) {
        case REHYDRATE:
            return rehydrateReducerState(authReducerKey, state, action);
        case AUTH_COMPLETED:
            return updateState(state, (newState: IAuthState) => {
                newState._isLoading = false; // '_' prefix is used to avoid property persistence
                newState.hasError = false;
                newState.isAuth = true;
                newState.clientId = action.clientId;
                newState.token = action.token;
                newState.refreshToken = action.refreshToken;
                newState.email = action.email;
            });
        case AUTH_REFRESH_STARTED:
            return updateState(state, (newState: IAuthState) => {
                newState._isLoading = true; // '_' prefix is used to avoid property persistence
                newState.hasError = false;
                newState.isAuth = false;
                newState.token = null;
                newState.refreshToken = null;
            });
        case AUTH_REFRESH_COMPLETED:
            return updateState(state, (newState: IAuthState) => {
                newState._isLoading = false; // '_' prefix is used to avoid property persistence
                newState.hasError = false;
                newState.isAuth = true;
                newState.token = action.token;
                newState.refreshToken = action.refreshToken;
            });
        case AUTH_REFRESH_FAILED:
            return updateState(state, (newState: IAuthState) => {
                newState._isLoading = false; // '_' prefix is used to avoid property persistence
                newState.hasError = true;
                newState.isAuth = false;
                newState.token = null;
                newState.refreshToken = null;
                newState.clientId = null;
            });
        case AUTH_LOG_OUT:
            return createState<IAuthState>(
                {
                    _isLoading: false,
                    hasError: false,
                    isAuth: false,
                    clientId: null,
                    token: null,
                    refreshToken: null,
                    email: state.email
                });
        default:
            return state;
    }
}

/** Reducer object (name: reducer function) */
export const authReducers = {
    [authReducerKey]: authReducer
};
