import {
    Directive, ElementRef, EventEmitter, Host, HostBinding, HostListener, Input, OnDestroy, OnInit, Output
} from '@angular/core';
import { isDescendant } from '@myia/ngx-core';

export const ALWAYS = 'always';
export const DISABLED = 'disabled';
export const OUTSIDECLICK = 'outsideClick';
export const NONINPUT = 'nonInput';

export class DropdownService {
    private openScope?: DropdownDirective;

    private closeDropdownBind = this.closeDropdown.bind(this);
    private keyBindFilterBind = this.keyBindFilter.bind(this);

    open(dropdownScope: DropdownDirective): void {
        if (!this.openScope) {
            window.document.addEventListener('click', this.closeDropdownBind, true);
            window.document.addEventListener('keydown', this.keyBindFilterBind);
        }

        if (this.openScope && this.openScope !== dropdownScope) {
            this.openScope.isOpen = false;
        }

        this.openScope = dropdownScope;
    }

    close(dropdownScope: DropdownDirective): void {
        if (this.openScope !== dropdownScope) {
            return;
        }

        this.openScope = void 0;
        window.document.removeEventListener('click', this.closeDropdownBind, true);
        window.document.removeEventListener('keydown', this.keyBindFilterBind);
    }

    private closeDropdown(event: MouseEvent | null): void {
        if (!this.openScope) {
            return;
        }

        if (event && this.openScope.autoClose === DISABLED) {
            return;
        }

        if (event && this.openScope.toggleEl) {
            if (this.openScope.toggleEl.nativeElement === event.target || isDescendant(this.openScope.toggleEl.nativeElement, event.target)) {
                return;
            }
        }

        if (event && this.openScope.autoClose === NONINPUT &&
            this.openScope.menuEl &&
            /input|textarea/i.test((event.target as any).tagName) &&
            this.openScope.menuEl.nativeElement.contains(event.target)) {
            return;
        }

        if (event && this.openScope.autoClose === OUTSIDECLICK &&
            this.openScope.menuEl &&
            this.openScope.menuEl.nativeElement.contains(event.target)) {
            return;
        }

        this.openScope.isOpen = false;
    }

    private keyBindFilter(event: KeyboardEvent): void {
        if (event.which === 27) {
            this.openScope?.focusToggleElement();
            this.closeDropdown(null);
            return;
        }

        if (this.openScope?.keyboardNav && this.openScope.isOpen &&
            (event.which === 38 || event.which === 40)) {
            event.preventDefault();
            event.stopPropagation();
            this.openScope.focusDropdownEntry(event.which);
        }
    }
}

export let dropdownService = new DropdownService();

@Directive({selector: '[dropdown]'})
export class DropdownDirective implements OnInit, OnDestroy {

    @Input() autoClose?: string;
    @Input() keyboardNav = false;
    // enum string: ['always', 'outsideClick', 'disabled']
    @Input() appendToBody = false;

    @Output() toggled = new EventEmitter<boolean>(false);
    @Output() isOpenChange = new EventEmitter<boolean>(false);

    @HostBinding('class.dropdown')
    addClass = true;

    // index of selected element
    selectedOption?: number;
    // drop menu html
    menuEl?: ElementRef;
    // drop down toggle element
    toggleEl?: ElementRef;
    el?: ElementRef;
    private _isOpen = false;

    @HostBinding('class.open')
    @Input()
    get isOpen(): boolean {
        return this._isOpen;
    }

    constructor(el: ElementRef) {
        // @Query('dropdownMenu', {descendants: false})
        // dropdownMenuList:QueryList<ElementRef>) {
        this.el = el;
        // todo: bind to route change event
    }

    set isOpen(value: boolean) {
        // console.log('isOpen:' + value);
        this._isOpen = value;

        // todo: implement after porting position
        // if (this.appendToBody && this.menuEl) {
        //
        // }

        // todo: $animate open<->close transitions, as soon as ng2Animate will be
        // ready
        if (this.isOpen) {
            this.focusToggleElement();
            dropdownService.open(this);
        } else {
            dropdownService.close(this);
            this.selectedOption = void 0;
        }
        this.toggled.emit(this.isOpen);
        this.isOpenChange.emit(this.isOpen);
        // todo: implement call to setIsOpen if set and function
    }

    ngOnInit(): void {
        this.autoClose = this.autoClose || NONINPUT;
        if (this.isOpen) {
            // todo: watch for event get-isOpen?
        }
    }

    ngOnDestroy(): void {
        if (this.appendToBody && this.menuEl) {
            this.menuEl.nativeElement.remove();
        }
    }

    set dropDownMenu(dropdownMenu: { el: ElementRef }) {
        // init drop down menu
        this.menuEl = dropdownMenu.el;

        if (this.appendToBody) {
            window.document.body.appendChild(this.menuEl.nativeElement);
        }
    }

    set dropDownToggle(dropdownToggle: { el: ElementRef }) {
        // init toggle element
        this.toggleEl = dropdownToggle.el;
    }

    toggle(open?: boolean): boolean {
        return this.isOpen = arguments.length ? !!open : !this.isOpen;
    }

    focusDropdownEntry(keyCode: number): void {
        // If append to body is used.
        let hostEl = this.menuEl ?
            this.menuEl.nativeElement :
            this.el?.nativeElement.getElementsByTagName('ul')[0];

        if (!hostEl) {
            // todo: throw exception?
            return;
        }

        let elems = hostEl.getElementsByTagName('a');
        if (!elems || !elems.length) {
            // todo: throw exception?
            return;
        }

        // todo: use parseInt to detect isNumber?
        // todo: or implement selectedOption as a get\set pair with parseInt on set
        switch (keyCode) {
            case (40):
                if (typeof this.selectedOption !== 'number') {
                    this.selectedOption = 0;
                    break;
                }

                if (this.selectedOption === elems.length - 1) {
                    break;
                }

                this.selectedOption++;
                break;
            case (38):
                if (typeof this.selectedOption !== 'number') {
                    return;
                }

                if (this.selectedOption === 0) {
                    // todo: return?
                    break;
                }

                this.selectedOption--;
                break;
            default:
                break;
        }

        if (this.selectedOption) {
            elems[this.selectedOption].focus();
        }
    }

    focusToggleElement(): void {
        if (this.toggleEl) {
            this.toggleEl.nativeElement.focus();
        }
    }
}

@Directive({
    selector: '[dropdown][dropdownKeyboardNav]'
})
export class KeyboardNavDirective {
    private dd: DropdownDirective;
    private el: ElementRef;

    constructor(dd: DropdownDirective, el: ElementRef) {
        this.dd = dd;
        this.el = el;
        console.warn('keyboard-nav deprecated');
        dd.keyboardNav = true;
    }

    @HostListener('keydown', ['$event'])
    onKeydown(event: KeyboardEvent): void {
        if (event.which !== 40 && event.which !== 38) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        let elems = this.dd.menuEl?.nativeElement.getElementsByTagName('a');

        switch (event.which) {
            case (40):
                if (typeof this.dd.selectedOption !== 'number') {
                    this.dd.selectedOption = 0;
                    break;
                }

                if (this.dd.selectedOption === elems.length - 1) {
                    break;
                }

                this.dd.selectedOption++;
                break;
            case (38):
                if (typeof this.dd.selectedOption !== 'number') {
                    return;
                }

                if (this.dd.selectedOption === 0) {
                    // todo: return?
                    break;
                }

                this.dd.selectedOption--;
                break;
            default:
                break;
        }
        if (this.dd.selectedOption) {
            elems[this.dd.selectedOption].nativeElement.focus();
        }
    }
}

@Directive({selector: '[dropdownToggle]'})
export class DropdownToggleDirective implements OnInit {
    @HostBinding('class.disabled')
    @Input() disabled: boolean = false;

    @HostBinding('class.dropdown-toggle')
    @HostBinding('attr.aria-haspopup')
    addClass: boolean = true;

    dropdown: DropdownDirective;
    el: ElementRef;

    constructor(@Host() dropdown: DropdownDirective, el: ElementRef) {
        this.dropdown = dropdown;
        this.el = el;
    }

    ngOnInit(): void {
        this.dropdown.dropDownToggle = this;
    }

    @HostBinding('attr.aria-expanded')
    get isOpen(): boolean {
        return this.dropdown.isOpen;
    }

    @HostListener('click', ['$event'])
    toggleDropdown(event: MouseEvent): boolean {
        event.stopPropagation();

        if (!this.disabled) {
            this.dropdown.toggle();
        }
        return false;
    }
}

@Directive({selector: '[dropdownMenu]'})
export class DropdownMenuDirective implements OnInit {
    dropdown: DropdownDirective;
    el: ElementRef;

    constructor(@Host() dropdown: DropdownDirective, el: ElementRef) {
        this.dropdown = dropdown;
        this.el = el;
    }

    ngOnInit(): void {
        this.dropdown.dropDownMenu = this;
    }
}

export interface DropdownMenuInterface {
    el: ElementRef;
    templateUrl: string;
}

export interface DropdownToggleInterface {
    el: ElementRef;
}

export const DROPDOWN_DIRECTIVES: Array<any> = [DropdownDirective, DropdownToggleDirective, KeyboardNavDirective, DropdownMenuDirective];
