import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CultureService, LocalizationService } from '@myia/ngx-localization';
import { AppConfig } from '../appConfig';
import { AppMode } from '../appMode';

@Injectable({ providedIn: 'root' })
export class PageTitleService {
    private _eventName?: string;
    constructor(private _titleService: Title, private _cultureService: CultureService, private _localizationService: LocalizationService) {
        this._cultureService.onChange.subscribe(() => {
            this.updateTitle();
        });
        this.updateTitle();
    }

    setEventName(eventName: string | undefined) {
        this._eventName = eventName;
        this.updateTitle();
    }

    private updateTitle() {
        const titleKey = AppConfig.appMode === AppMode.myiaModerator ? 'MyiaForModerators' : 'MyMyia';
        if (this._eventName) {
            this._titleService.setTitle(`${this._localizationService.translate(titleKey)} - ${this._eventName}`);
        } else {
            this._titleService.setTitle(`${this._localizationService.translate(titleKey)}`);
        }

    }
}
