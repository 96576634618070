import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { NgChanges } from '@myia/ngx-core';

@Component({
    selector: 'control-messages',
    styleUrls: ['./controlMessages.component.scss'],
    template: `
        <div class="controlMessage" *ngIf="isArray && messages?.length">
            <div class="msg" *ngFor="let msg of messagesArray; trackBy: trackMessage">{{msg|trans}}</div>
        </div>
        <div class="controlMessage" *ngIf="!isArray && messages">
            <div class="msg" [innerHTML]="messages"></div>
        </div>
    `
})
export class ControlMessagesComponent implements OnChanges {

    get messagesArray() {
        return this.messages as Array<string> | null;
    }

    @Input() messages: Array<string> | string | null = null;

    @HostBinding('class') hostClasses = 'controlMessagesContainer';

    isArray = false;

    trackMessage(index: number, msg: string): string {
        return msg;
    }

    ngOnChanges(changes: NgChanges<ControlMessagesComponent>) {
        if (changes.messages) {
            this.isArray = Array.isArray(changes.messages.currentValue);
        }
    }
}
