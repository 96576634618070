import { animation, animate, style } from '@angular/animations';

export const fadeAnimation = animation([
        style({
            height: '{{ heightFrom }}',
            opacity: '{{ opacityFrom }}',
        }),
        animate('{{ time }} {{ delay }} ease',
            style({
                    height: '{{ heightTo }}',
                    opacity: '{{ opacityTo }}'
                }
            )
        ),
    ],
    {params: {heightFrom: '*', heightTo: '*', opacityFrom: 0, opacityTo: 1, time: '0.3s', delay: '0s'}}
);
