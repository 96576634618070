import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnDestroy } from '@angular/core';
import { Placement } from '@floating-ui/dom';
import { CultureService } from '@myia/ngx-localization';
import { StatusBarManager } from '../services/statusBarManager';

@Component({
    selector: 'top-bar-culture-switch',
    styleUrls: ['./topBarCultureSwitch.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <button class="topBarButton buttonCultureSwitch" (click)="switchCulture($event)" [tooltip]="'Menu.ChangeLanguage'|trans" tooltipPlacement="bottom" tooltipClass="noWrap" [tooltipOffset]="[0,10]" tooltipContainer="body" [tooltipEnable]="!hideToolTip" [tooltipDontHideOnChange]="true"><svg-icon name="switch-language"></svg-icon><progress-indicator-circle *ngIf="changingCulture && !hideToolTip" indicatorClass="extraSmall cultureLoader"></progress-indicator-circle></button>
        <div class="culturesListPlaceholder" [tooltip]="culturesTooltip" tooltipClass="cultureList" tooltipTrigger="none" [tooltipEnable]="showCultures" tooltipContainer="body" boundaryContainer="body" [tooltipPlacement]="popUpPlacement" [tooltipOffset]="[0,5]" [hideWhenReferenceHidden]="false"></div>
        <ng-template #culturesTooltip>
            <button (click)="setCulture('en')" [ngClass]="{selected: selectedCulture === 'en'}"><span class="abb">EN</span><span class="title">English</span></button>
            <button (click)="setCulture('cs')" [ngClass]="{selected: selectedCulture === 'cs'}"><span class="abb">CS</span><span class="title">Česky</span></button>
        </ng-template>
    `,
})
export class TopBarCultureSwitchComponent implements OnDestroy {
    @HostBinding('class.cultureSwitch') hostClass = true;

    @HostBinding('class.horizontal') @Input() horizontal = false;

    @Input() hideToolTip= false;
    @Input() popUpPlacement: Placement | 'auto' = 'auto';
    showCultures = false;
    changingCulture = false;
    selectedCulture?: string;

    private closePopupBind = this.closePopUp.bind(this);

    constructor(public cultureService: CultureService, private _statusBarManager: StatusBarManager, private _changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnDestroy() {
        window.document.removeEventListener('click', this.closePopupBind);
    }

    switchCulture($event: MouseEvent) {
        $event.stopPropagation();
        $event.preventDefault();
        this.selectedCulture = this.cultureService.currentCulture;
        this.showCultures = !this.showCultures;
        this.updateDocClickHandler();
    }

    setCulture(userLang: string) {
        this.changingCulture = true;
        this._changeDetectorRef.markForCheck();
        this.cultureService.use(userLang).subscribe(() => {
            this.changingCulture = false;
            this._changeDetectorRef.markForCheck();
        });
        this.showCultures = false;
        this.updateDocClickHandler();
    }

    private updateDocClickHandler() {
        if (this.showCultures) {
            window.document.addEventListener('click', this.closePopupBind);
        } else {
            window.document.removeEventListener('click', this.closePopupBind);
        }
    }

    private closePopUp(event: MouseEvent): void {
        this.showCultures = false;
        this._changeDetectorRef.markForCheck();
    }
}


