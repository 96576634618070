import { transition, trigger, useAnimation } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import { fadeAnimation } from '@shared';
import { SideBarComponent } from './sideBar';

@Component({
    selector: 'side-bar-item',
    styleUrls: ['./sideBarItem.component.scss'],
    template: `
        <a [routerLink]="linkUrl" [queryParams]="queryParams" routerLinkActive="router-link-active" [ngClass]="{collapsed: sideBar.isCollapsed, withCounter: showCounter}"><svg-icon-data *ngIf="iconData" [data]="iconData"></svg-icon-data><svg-icon *ngIf="!iconData" name="{{icon}}"></svg-icon><span class="menuText">{{itemTitle|trans}}</span><span class="counter" *ngIf="counter" [@counter]>{{counter}}</span></a>
    `,
    animations: [
        trigger('counter', [
            transition(':enter', [
                useAnimation(fadeAnimation, {
                    params: {
                        opacityTo: 1,
                        time: '0.6s'
                    }
                })
            ]),
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideBarItemComponent {
    @Input() itemTitle?: string;
    @Input() icon?: string;
    @Input() iconData?: string;
    @Input() linkUrl: any[] | string | null | undefined;
    @Input() queryParams: any;
    @Input() counter?: string;
    @Input() showCounter = false;

    @HostBinding('class') hostClasses?: string;

    @Input() set classNames (value: string | null) {
        this._classNames = value;
        this.updateHostClasses();
    }

    private _classNames: string | null = '';

    constructor(public sideBar: SideBarComponent, _changeDetectorRef: ChangeDetectorRef) {
        sideBar.collapseButtonClicked.subscribe(() => {
            setTimeout(() => {
                _changeDetectorRef.markForCheck();
            });
        });
    }

    private updateHostClasses() {
        this.hostClasses = this._classNames || '';
    }

}
