import { ComponentRef, Injectable, Injector, Type, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { calculateAspectRatioFit, sortBy, whenTransitionEnds } from '@myia/ngx-core';
import { BehaviorSubject, Subject } from 'rxjs';
import { LocationUrlService } from './locationService';

@Injectable({providedIn: 'root'})
export class FloatingElementService {

    activated = new BehaviorSubject<boolean>(false);
    minimized = new BehaviorSubject<boolean>(false);
    currentBoundaryElement = new BehaviorSubject<HTMLElement>(document.body);
    boundsChanged = new Subject<void>();

    private _minimized = false;
    private _closing = false;
    private _clickUrl?: string;
    private _viewContainerRef?: ViewContainerRef;
    private _floatingComponent?: ComponentRef<any>;
    private _userPosition?: { x: number; y: number };
    private _initialPositionDone = false;
    private _boundingElements: { [key: string]: HTMLElement } = {};
    private _aspectRatio?: number;
    private _locationUrl?: string;

    get floatingComponent() {
        return this._floatingComponent;
    }

    constructor(private _router: Router, private _locationUrlService: LocationUrlService) {
        _locationUrlService.url.subscribe(url => {
            this._locationUrl = url;
            this.updateBounds();
        });
    }

    registerFloatingContainer(viewContainerRef: ViewContainerRef | undefined) {
        this._viewContainerRef = viewContainerRef;
        this._viewContainerRef?.element?.nativeElement?.parentElement?.addEventListener('click', (event: MouseEvent) => {
            if (this._minimized && this._clickUrl && !(event.target as HTMLElement).closest('.free-dragging')) {
                this._router.navigateByUrl(this._clickUrl);
                this.setMinimized(false);
            }
        });
    }

    registerFloatingElement(clickUrl?: string) {
        setTimeout(() => {
            this._viewContainerRef?.element?.nativeElement?.parentElement?.classList.add('show');
            this._clickUrl = clickUrl;
        });
        this.activated.next(true);
    }

    unregisterFloatingElement(noAnim: boolean = false) {
        this._viewContainerRef?.detach();
        const elToAnimate = this._viewContainerRef?.element?.nativeElement?.parentElement;
        if (elToAnimate) {
            if (!noAnim && elToAnimate.classList.contains('show')) {
                if (!this._closing) {
                    this._closing = true;
                    whenTransitionEnds(elToAnimate).then(() => {
                        elToAnimate.classList.remove('show');
                        elToAnimate.classList.remove('closing');
                        this._floatingComponent?.destroy();
                        this._floatingComponent = undefined;
                        this.setMinimized(false);
                        this._closing = false;
                    });
                    elToAnimate.classList.add('closing');
                }
            } else {
                this._floatingComponent?.destroy();
                this._floatingComponent = undefined;
                this.setMinimized(false);
            }
        }
        this.activated.next(false);
    }

    registerBoundingContainer(routeUrl: string, boundingElement: HTMLElement) {
        this._boundingElements[routeUrl] = boundingElement;
        this.updateBounds();
    }

    unregisterBoundingContainer(routeUrl: string | undefined) {
        if (routeUrl) {
            delete this._boundingElements[routeUrl];
            this.updateBounds();
        }
    }

    createComponent(componentType: Type<any>, _: Injector) {
        this._floatingComponent = this._viewContainerRef?.createComponent(componentType);
        return this._floatingComponent;
    }

    setMinimized(minimized: boolean) {
        if (!minimized) {
            this._aspectRatio = undefined;
        }
        this._minimized = minimized;
        if (this._viewContainerRef) {
            const floatingContainer = this._viewContainerRef.element.nativeElement.parentElement;
            if (minimized) {
                const floatingContainer = this._viewContainerRef.element.nativeElement.parentElement;
                this.updateBounds();

                floatingContainer.classList.remove('noDrag');
                floatingContainer.classList.add('minimized');
            } else {
                floatingContainer.classList.add('noDrag');
                floatingContainer.classList.remove('minimized');
                Array.from<HTMLElement>(floatingContainer.children).forEach(child => {
                    child.style.width = '';
                    child.style.height = '';
                    child.style.transform = 'scale(1)';
                });
            }
        }
        this.minimized.next(minimized);
    }

    setBounds(bounds: { top?: string; left?: string; right?: string, bottom?: string, width?: string; height?: string }) {
        console.log('bounds width: ' + JSON.stringify(bounds));
        if (this._viewContainerRef) {
            const floatingContainer = this._viewContainerRef.element.nativeElement.parentElement;
            floatingContainer.style.width = bounds.width;
            floatingContainer.style.height = bounds.height;
            if (bounds.left && floatingContainer.style.left) {
                floatingContainer.style.left = bounds.left;
            } else {
                floatingContainer.style.left = 'auto';
            }
            if (bounds.top && floatingContainer.style.top) {
                floatingContainer.style.top = bounds.top;
            } else {
                floatingContainer.style.top = 'auto';
            }
            if (bounds.right && floatingContainer.style.right) {
                floatingContainer.style.right = bounds.right;
            } else {
                floatingContainer.style.right = 'auto';
            }
            if (bounds.bottom && floatingContainer.bottom) {
                floatingContainer.style.bottom = bounds.bottom;
            } else {
                floatingContainer.style.bottom = 'auto';
            }
            if (!this._initialPositionDone) {
                this._initialPositionDone = true;
                floatingContainer.classList.add('ready');
            }
        }
    }

    setMinimizedPosition(pos: { x: number; y: number }) {
        this._userPosition = pos;
    }

    updateBounds() {
        const floatingContainer = this._viewContainerRef?.element?.nativeElement?.parentElement;
        const boundingContainer = (this._locationUrl && this.getBoundaryContainer(this._locationUrl)) ?? floatingContainer ?? document.body;
        if (boundingContainer !== this.currentBoundaryElement.value) {
            this.currentBoundaryElement.next(boundingContainer);
        }
        if (this._minimized) {
            const scale = 0.25;
            const boundingContainerBounds = boundingContainer.getBoundingClientRect();
            if (!this._aspectRatio) {
                this._aspectRatio = boundingContainerBounds.height / boundingContainerBounds.width;
            }

            const minimizedSize = calculateAspectRatioFit(boundingContainerBounds.width, boundingContainerBounds.width * this._aspectRatio, boundingContainerBounds.width * scale, boundingContainerBounds.height * scale);
            minimizedSize.width = Math.ceil(minimizedSize.width);
            minimizedSize.height = Math.ceil(minimizedSize.height);

            console.log('minimizedSize: ' + JSON.stringify(minimizedSize));
            if (this._userPosition) {
                if (this._userPosition.x === null || this._userPosition.y === null || this._userPosition.x < boundingContainerBounds.left || this._userPosition.y < boundingContainerBounds.top || this._userPosition.x + minimizedSize.width > boundingContainerBounds.right || this._userPosition.y + minimizedSize.height > boundingContainerBounds.bottom) {
                    this._userPosition = undefined;
                }
            }
            console.log('_userPosition: ' + (this._userPosition ? JSON.stringify(this._userPosition) : 'null'));
            console.log('_boundingContainerBounds: ' + JSON.stringify(boundingContainerBounds));
            const floatingBounds = {
                top: this._userPosition ? `${this._userPosition.y}px` : `${boundingContainerBounds.top + 30}px`,
                left: this._userPosition ? `${this._userPosition.x}px` : `calc(100% - ${minimizedSize.width + 55 + (document.body.offsetWidth - boundingContainerBounds.right)}px)`,
                width: `${minimizedSize.width}px`,
                height: `${minimizedSize.height}px`
            };
            this.setBounds(floatingBounds);
            Array.from<HTMLElement>(floatingContainer.children).forEach(child => {
                child.style.transform = `scale(${minimizedSize.width / boundingContainerBounds.width})`;
                child.style.width = `${boundingContainerBounds.width}px`;
                child.style.height = `${boundingContainerBounds.width * (this._aspectRatio ?? 1)}px`;
            });

            this.currentBoundaryElement.next(boundingContainer);
        }
        this.boundsChanged.next();
    }

    applyBounds(el: HTMLElement) {
        if (el) {
            const elRect = el.getBoundingClientRect();
            if (elRect.width > 0 && elRect.height > 0) {
                const bounds = {
                    left: `${elRect.left}px`,
                    top: `${elRect.top}px`,
                    right: `${elRect.right}px`,
                    bottom: `${elRect.bottom}px`,
                    width: `${Math.ceil(elRect.width)}px`,
                    height: `${Math.ceil(elRect.height)}px`,
                };
                this.setBounds(bounds);
            }
        }
    }

    getBoundaryContainer(locationUrl: string) {
        // sort boundingElements by length of url match
        const sortedBoundingEls = sortBy(Object.keys(this._boundingElements).filter(url => locationUrl.indexOf(url) !== -1), u => u.length);
        return (sortedBoundingEls.length ? this._boundingElements[sortedBoundingEls[sortedBoundingEls.length - 1]] : null);
    }
}
