import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class LocationUrlService {
    url: BehaviorSubject<string> = new BehaviorSubject<string>(window.location.pathname);

    constructor(location: Location) {
        location.onUrlChange(url => {
            this.url.next(url);
        });
    }
}
