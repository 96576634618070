import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'checkWebUrl'
})
export class CheckWebUrlPipe implements PipeTransform {
    transform(url: string, webName: string): boolean {
        if (url) {
            switch (webName) {
                case 'linkedIn':
                    return !!url.match(/^(?:http(s)?:\/\/)?([\w.-]+\.)?linkedin\.com(\/)?.*$/);
                case 'meetup':
                    return !!url.match(/^(?:http(s)?:\/\/)?([\w.-]+\.)?meetup\.com(\/)?.*$/);
                case 'xing':
                    return !!url.match(/^(?:http(s)?:\/\/)?([\w.-]+\.)?xing\.com(\/)?.*$/);
            }
        }
        return false;
    }
}
