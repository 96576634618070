import { Injectable } from '@angular/core';
import { Logger } from '@myia/ngx-core';
import { CultureService, LocalizationService } from '@myia/ngx-localization';
import { ToastManager } from '@myia/ngx-toast';

const _updateMsgKey = 'TOAST_MSG_KEY_APP_UPDATE';
const _PWAUpdatedMessage = 'MYIA_PWA_UPDATED';

// PWA Update event emulation - use Fiddler with following FiddlerScript
//
// static function OnBeforeResponse(oSession: Session) {
//     ...
//
//     // modify PWA service worker for testing PWA update
//     if (oSession.RequestMethod == 'GET' && oSession.PathAndQuery.IndexOf('service-worker.js') > 0) {
//         var updatedBody = oSession.GetResponseBodyAsString();
//         var regex = /"\/css\/main\.([^.]*)\.css","([^"]*)"/gm;
//         updatedBody = updatedBody.replace(regex, function(a, h1, h2){
//             h2 = ((new Date().getTime() * 10000) + 621355968000000000);
//             return '"/css/main.' + h1 +'.css","'+h2+'"';
//         });
//         oSession.utilSetResponseBody(updatedBody);
//     }
//
// }
//

@Injectable({ providedIn: 'root' })
export class PWAUpdateService {

    private _updateIsAvailable = false;

    constructor(private _toastManager: ToastManager, private _logger: Logger, private _localizationService: LocalizationService, private _cultureService: CultureService) {
        // subscribe to onChange event, to store form data when culture changes
        this._cultureService.onChange.subscribe(() => {
            if (this._updateIsAvailable) {
                // update toast
                this.showUpdateToast();
            }
        });

        const receivePWAUpdatedMessage = (event: any) => {
            if (event.data === _PWAUpdatedMessage) {
                this._updateIsAvailable = true;
                this.showUpdateToast();
            }
        }

        window.addEventListener('message', receivePWAUpdatedMessage.bind(this), false);

    }

    private showUpdateToast() {
        const buttons = [
            {
                title: this._localizationService.translate('Update_App'),
                clickHandler:(toast: any) => {
                    this._toastManager.clearToast(toast);
                    this._logger.log('PWA activateUpdate started.');
                    window.location.reload();
                }
            }
        ];
        this._toastManager.clearToastByKey(_updateMsgKey, true);
        this._toastManager.info( this._localizationService.translate('There_is_Update_Available'), { toastKey: _updateMsgKey, timeOut: 0, icon: 'update', iconPrefix: 'svgIcon', cssClass: 'rightButtons',  buttons });
    }
}
