import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ContentChild, Directive, ElementRef, EventEmitter, inject, Inject, Input, NgZone, OnInit, Output, } from '@angular/core';
import { EmitterService, LAYOUT_RESIZED } from '@myia/ngx-core';
import { OnDestroyDirective } from '@myia/ngx-core-ui';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { FreeDraggingHandleDirective } from './freeDraggingHandle';

@Directive({
    selector: '[freeDragging]',
    hostDirectives: [
        OnDestroyDirective
    ],
})
export class FreeDraggingDirective implements OnInit, AfterViewInit {
    @ContentChild(FreeDraggingHandleDirective)
    handle?: FreeDraggingHandleDirective;
    handleElement?: HTMLElement;
    @Input() boundaryElement?: Element | null;
    @Input() dragPadding: { left?: number, top?: number, right?: number, bottom?: number } | null = null;
    @Output() movedTo = new EventEmitter<{ x: number, y: number }>();

    private _element?: HTMLElement;
    private _minBoundX?: number;
    private _minBoundY?: number;
    private _maxBoundX?: number;
    private _maxBoundY?: number;
    private _destroy$ = inject(OnDestroyDirective).destroy$;

    private onResizeBind: EventListener = this.checkBounds.bind(this);

    constructor(
        private elementRef: ElementRef,
        @Inject(DOCUMENT) private document: Document,
        private _zone: NgZone
    ) {
    }

    ngOnInit() {
        fromEvent(window, 'resize').pipe(
            debounceTime(500),
            takeUntil(this._destroy$)
        ).subscribe(() => {
            this.checkBounds();
        });
        EmitterService.getEvent(LAYOUT_RESIZED).pipe(
            takeUntil(this._destroy$)
        ).subscribe(this.onResizeBind);

    }

    ngAfterViewInit(): void {
        this._element = this.elementRef.nativeElement as HTMLElement;
        this.handleElement = this.handle?.elementRef?.nativeElement || this._element;
        this.initDrag();
    }

    private initDrag(): void {
        this._zone.runOutsideAngular(
            () => {
                const dragStart$ = fromEvent<MouseEvent>(this.handleElement!, 'mousedown');
                const dragEnd$ = fromEvent<MouseEvent>(this.document, 'mouseup');
                const drag$ = fromEvent<MouseEvent>(this.document, 'mousemove').pipe(
                    takeUntil(dragEnd$)
                );

                let dragSub: Subscription;

                dragStart$.pipe(
                    takeUntil(this._destroy$)
                ).subscribe((event: MouseEvent) => {
                    if (!this._element?.classList.contains('noDrag')) {
                        event.preventDefault();
                        event.stopPropagation();

                        // disable marked elements to prevent mouse event collisions
                        Array.from(this.document.querySelectorAll<HTMLElement>('.ignoreWhiteDraggingOver')).forEach(el => {
                            el.style.pointerEvents = 'none';
                        });

                        const startX = event.clientX;
                        const startY = event.clientY;
                        const startBounds = this._element?.getBoundingClientRect();

                        let dragStarted = false;
                        dragSub = drag$.pipe(
                            takeUntil(this._destroy$)
                        ).subscribe((event: MouseEvent) => {
                            if (!dragStarted) {
                                dragStarted = true;
                                this._element?.classList.add('free-dragging');
                                this.updateContainerBoundary();
                            }
                            event.preventDefault();
                            event.stopPropagation();

                            const x = event.clientX + (startBounds!.x - startX);
                            const y = event.clientY + (startBounds!.y - startY);

                            this.updatePosition(x, y);

                        });
                    }
                });

                dragEnd$.pipe(
                    takeUntil(this._destroy$)
                ).subscribe((event: MouseEvent) => {
                    event.preventDefault();
                    event.stopPropagation();
                    // enable marked elements back
                    Array.from(this.document.querySelectorAll<HTMLElement>('.ignoreWhiteDraggingOver')).forEach(el => {
                        el.style.pointerEvents = 'auto';
                    });
                    setTimeout(() => { // remove class later to be able to detect dragging in click event handlers
                        this._element?.classList.remove('free-dragging');
                    });
                    if (dragSub) {
                        dragSub.unsubscribe();
                    }
                });

            });
    }

    private checkBounds() {
        // console.log(`check bounds`);
        if (!this.updateContainerBoundary()) {
            const dragElRect = this.elementRef.nativeElement.getBoundingClientRect();
            this.updatePosition(dragElRect.left, dragElRect.top);
        }
    }

    private updateContainerBoundary(): boolean {
        const draggingBoundaryElement = this.boundaryElement ?? document.body;
        if (draggingBoundaryElement) {
            const dragElRect = this.elementRef.nativeElement.getBoundingClientRect();
            const boundaryRect = draggingBoundaryElement.getBoundingClientRect();
            const prevMinBoundX = this._minBoundX!;
            const prevMinBoundY = this._minBoundY!;
            const prevMaxBoundX = this._maxBoundX!;
            const prevMaxBoundY = this._maxBoundY!;
            const stickToRight = dragElRect.left + dragElRect.width / 2 > (this._minBoundX! + this._maxBoundX!) / 2;
            const stickToBottom = dragElRect.top + dragElRect.height / 2 > (this._minBoundY! + this._maxBoundY!) / 2;
            this._minBoundX = boundaryRect.left + (this.dragPadding?.left ?? 0);
            this._minBoundY = boundaryRect.top + (this.dragPadding?.top ?? 0);
            this._maxBoundX = boundaryRect.right - dragElRect.width - (this.dragPadding?.right ?? 0);
            this._maxBoundY = boundaryRect.bottom - dragElRect.height - (this.dragPadding?.bottom ?? 0);
            const updateLeft = this._minBoundX !== prevMinBoundX && !stickToRight;
            const updateTop = this._minBoundY !== prevMinBoundY && !stickToBottom;
            const updateRight = this._maxBoundX !== prevMaxBoundX && stickToRight;
            const updateBottom = this._maxBoundY !== prevMaxBoundY && stickToBottom;
            if (updateLeft || updateTop || updateRight || updateBottom) {
                this.updatePosition(dragElRect.left + (updateRight ? this._maxBoundX - prevMaxBoundX : (updateLeft ? this._minBoundX - prevMinBoundX : 0)), dragElRect.top + (updateBottom ? this._maxBoundY - prevMaxBoundY : (updateTop ? this._minBoundY - prevMinBoundY : 0)));
                return true;
            }
        }
        return false;
    }

    private updatePosition(x: number, y: number) {
        if (!this._element?.classList.contains('noDrag')) {
            const currentX = Math.max(this._minBoundX!, Math.min(x, this._maxBoundX!));
            const currentY = Math.max(this._minBoundY!, Math.min(y, this._maxBoundY!));
            this._element!.style.left = currentX + 'px';
            this._element!.style.top = currentY + 'px';
            //console.log(`free dragged el pos changed: ${currentX}, ${currentY}`)
            this.movedTo.emit({x: currentX, y: currentY});
        }
    }
}
