import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { NgResizeObserver, ngResizeObserverProvidersWithPonyfill } from 'ng-resize-observer';
import { map } from 'rxjs/operators';
import { FloatingElementService } from '../services/floatingElementService';

@Directive({
    selector: '[floatingElement]',
    providers: [...ngResizeObserverProvidersWithPonyfill]
})
export class FloatingElementDirective implements AfterViewInit {
    constructor(private _floatingElementService: FloatingElementService, private resize$: NgResizeObserver, private _elementRef: ElementRef) {
    }

    ngAfterViewInit() {
        this.resize$.pipe(
            map(() => {
                this._measureRect();
            })
        ).subscribe();
        this._measureRect();
    }
    private _measureRect() {
        this._floatingElementService.applyBounds(this._elementRef.nativeElement);
    }
}
