import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'toString'
})
export class ToStringPipe implements PipeTransform {
    transform(value: any): string | undefined {
        return value ? value.toString() : undefined;
    }
}
