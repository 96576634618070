import { Directive, ElementRef, Input } from '@angular/core';
import { cubicInOut, EmitterService, getElementOffset, getParentScroll, isScrollable, scrollToWithAnimation } from '@myia/ngx-core';

export const ScrollControlEmitterChannelName = 'SCROLL_CONTROL_CHANNEL';

export enum ScrollAlignment {
    Top,
    Bottom
}

export interface IScrollArgs {
    element?: any;
    top?: number;
    alignment?: ScrollAlignment;
    delay?: number; // start scroll with delay
    offset?: number; // target position offset
    scrollDuration?: number;
    completed?: () => void;
}

export function ScrollElementToPosition(scrollControlName: string | Array<string>, scrollArgs: IScrollArgs) {
    if (!Array.isArray(scrollControlName)) {
        scrollControlName = [scrollControlName];
    }
    const completedPromises: Array<Promise<void>> = [];
    scrollControlName.forEach(scName => {
        completedPromises.push(new Promise(resolve => {
                EmitterService.getEvent(getScrollChannelName(scName)).emit({
                    scrollArgs: {
                        ...scrollArgs,
                        completed: resolve
                    }
                });
            })
        );
    });
    Promise.all(completedPromises).then(() => {
        if (scrollArgs.completed) {
            scrollArgs.completed();
        }
    });
}

function getScrollChannelName(scrollControlName: string): string {
    return `${ScrollControlEmitterChannelName}_${scrollControlName}`;
}

type ScrollEventArg = {
    scrollArgs: IScrollArgs;
};

@Directive({
    selector: '[scrollControl]'
})
export class ScrollControlDirective {
    @Input() scrollDuration: number = 500; // 500ms

    private _scrollControl?: string;

    get scrollControl(): string | undefined {
        return this._scrollControl;
    }

    get scrollElement(): HTMLElement {
        return this._element.nativeElement;
    }

    @Input() set scrollControl(value: string | undefined) {
        this._scrollControl = value;
        if (this._scrollControl) {
            EmitterService.getEvent(getScrollChannelName(this._scrollControl)).subscribe(this.onScroll.bind(this));
        }
    }

    constructor(private _element: ElementRef) {
    }

    private onScroll($event: ScrollEventArg) {
        setTimeout(() => {
            if (this._element.nativeElement.scrollHeight > this._element.nativeElement.offsetHeight && isScrollable(this._element.nativeElement)) {
                const elementPosInfo = $event.scrollArgs.element ? getElementOffset($event.scrollArgs.element) : null;
                const parentScroll = $event.scrollArgs.element ? getParentScroll($event.scrollArgs.element): null;
                const scrollPos = Math.max(
                $event.scrollArgs.element
                    ?
                        this._element.nativeElement.scrollTop - (this._element.nativeElement.stickyOffsetTop || 0)
                        + (elementPosInfo?.top ?? 0)
                        - (parentScroll ? getElementOffset(parentScroll).top : 0)
                        + ($event.scrollArgs.alignment && $event.scrollArgs.alignment === ScrollAlignment.Bottom ? ((elementPosInfo?.height ?? 0) - this._element.nativeElement.offsetHeight) : 0)
                        + ($event.scrollArgs.offset || 0)
                    :
                        $event.scrollArgs.top ?? 0
                    ,0
                );
                this.scrollTo(this._element.nativeElement, scrollPos, typeof $event.scrollArgs.scrollDuration === 'number' ? $event.scrollArgs.scrollDuration : this.scrollDuration, $event.scrollArgs.completed);
            } else {
                // no overflow -> don't scroll
                if ($event.scrollArgs.completed) {
                    $event.scrollArgs.completed();
                }
            }
        }, $event.scrollArgs.delay || 0);
    }

    private scrollTo(elementToScroll: any, to: number, scrollSpeed: number, callback?: Function) {
        if (scrollSpeed > 0) {
            scrollToWithAnimation(elementToScroll, to, scrollSpeed, cubicInOut, callback);
        }
        else {
            elementToScroll.scrollTop = to;
            if (callback && typeof(callback) === 'function') {
                // the scroll is done so lets callback
                callback();
            }
        }
    }

}
