import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, HostListener, inject, Input, OnChanges, Output, Self, ViewChild, } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { NgChanges } from '@myia/ngx-core';

@Component({
    selector: 'splitter',
    template: ''
})
export class SplitterComponent implements OnChanges, AfterViewInit {
    _sanitizer = inject(DomSanitizer);

    @HostBinding('style')
    get myStyle(): SafeStyle {
        return this._sanitizer.bypassSecurityTrustStyle('height: 100%;');
    }
    @ViewChild('primaryComponent', {static: true}) primaryComponent?: ElementRef;
    @ViewChild('secondaryComponent', {static: true}) secondaryComponent?: ElementRef;

    @Input() initialRatio = 0.5;
    @Input() primaryMinSize = 0;
    @Input() secondaryMinSize = 0;
    @Input() separatorThickness = 5;
    @Input() primaryToggledOff = false;
    @Input() secondaryToggledOff = false;
    @Input() localStorageKey: string | null = null;
    @Output() notifySizeDidChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() notifyBeginResizing: EventEmitter<any> = new EventEmitter<any>();
    @Output() notifyEndedResizing: EventEmitter<any> = new EventEmitter<any>();

    primarySizeBeforeTogglingOff?: number;
    dividerSize = 8.0;
    isResizing = false;

    constructor(@Self() protected self: ElementRef) {
    }

    protected get sizePropertyName(): 'offsetWidth' | 'offsetHeight' {
        if (this.self.nativeElement.nodeName === 'HORIZONTAL-SPLITTER') {
            return 'offsetHeight';
        } else {
            return 'offsetWidth';
        }
    }

    ngAfterViewInit(): void {
        this.checkBothToggledOff();

        if (!this.primaryToggledOff && !this.secondaryToggledOff) {
            let ratio: number = this.initialRatio;
            if (this.localStorageKey !== null) {
                const ratioStr = localStorage.getItem(this.localStorageKey);
                if (ratioStr !== null) {
                    ratio = +ratioStr;
                }
            }

            const size = ratio * this.self.nativeElement[this.sizePropertyName];
            this.applySizeChange(size);
        }
    }

    ngOnChanges(changes: NgChanges<SplitterComponent>): void {
        this.checkBothToggledOff();

        if (changes.primaryToggledOff) {
            if (changes.primaryToggledOff.currentValue) {
                this.primarySizeBeforeTogglingOff = this.getPrimarySize();
                this.applySizeChange(0);
            } else {
                this.applySizeChange(this.primarySizeBeforeTogglingOff!);
            }
        } else if (changes.secondaryToggledOff) {
            if (changes.secondaryToggledOff.currentValue) {
                this.primarySizeBeforeTogglingOff = this.getPrimarySize();
                this.applySizeChange(this.self.nativeElement[this.sizePropertyName]);
            } else {
                this.applySizeChange(this.primarySizeBeforeTogglingOff!);
            }
        }
    }

    getPrimarySize(): number | undefined {
        throw new Error(
            'SplitterComponent shouldn\'t be instantiated. Override this method.'
        );
    }

    getSecondarySize(): number | undefined {
        throw new Error(
            'SplitterComponent shouldn\'t be instantiated. Override this method.'
        );
    }

    dividerPosition(size: number, animate: boolean): void {
        throw new Error(
            'SplitterComponent shouldn\'t be instantiated. Override this method.'
        );
    }

    getAvailableSize(): number {
        return this.self.nativeElement[this.sizePropertyName] - this.dividerSize;
    }

    applySizeChange(size: number, animate = false): void {
        let primarySize = this.checkValidBounds(
            size,
            this.primaryMinSize,
            this.getAvailableSize() - this.secondaryMinSize
        );

        if (this.primaryToggledOff) {
            primarySize = 0;
        } else if (this.secondaryToggledOff) {
            primarySize = this.self.nativeElement[this.sizePropertyName];
        }

        this.dividerPosition(primarySize, animate);
        this.notifySizeDidChange.emit({
            primary: this.getPrimarySize(),
            secondary: this.getSecondarySize(),
            animate
        });
    }

    notifyWillChangeSize(resizing: boolean): void {
        this.isResizing = resizing;
        this.notifyBeginResizing.emit();
    }

    checkValidBounds(newSize: number, minSize: number, maxSize: number): number {
        if (newSize >= minSize && newSize <= maxSize) {
            return newSize;
        } else if (maxSize - newSize > 0) {
            return minSize;
        } else {
            return maxSize;
        }
    }

    checkBothToggledOff(): void {
        if (this.primaryToggledOff && this.secondaryToggledOff) {
            throw new Error(
                'You cannot toggle off both the primary and secondary component'
            );
        }
    }

    stopResizing(): void {
        this.isResizing = false;
        this.primaryComponent!.nativeElement.style.cursor = 'auto';
        this.secondaryComponent!.nativeElement.style.cursor = 'auto';

        if (this.localStorageKey) {
            const ratio =
                this.getPrimarySize()! / this.self.nativeElement[this.sizePropertyName];
            localStorage.setItem(this.localStorageKey, JSON.stringify(ratio));
        }

        this.notifyEndedResizing.emit();
    }

    @HostListener('mouseup')
    @HostListener('touchend')
    onMouseup(): void {
        if (this.isResizing) {
            this.stopResizing();
        }
    }

    @HostListener('document:mouseout', ['$event'])
    onDocumentLeave(event: MouseEvent): void {
        if (this.isResizing) {
            const from = event.relatedTarget as HTMLElement;
            if (!from || from.nodeName === 'HTML') {
                this.stopResizing();
            }
        }
    }
}
