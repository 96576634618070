import { IStatusBarMessage } from '../entities/statusBarMessage.interface';
export const ADD_STATUSBAR_MESSAGE = 'ADD_STATUSBAR_MESSAGE';
export const UPDATE_STATUSBAR_MESSAGE = 'UPDATE_STATUSBAR_MESSAGE';
export const REMOVE_STATUSBAR_MESSAGE = 'REMOVE_STATUSBAR_MESSAGE';
export const CLEAR_ALL_STATUSBAR_MESSAGES = 'CLEAR_ALL_STATUSBAR_MESSAGES';
export const SHOW_STATUSBAR_BUSY_LINE = 'SHOW_STATUSBAR_BUSY_LINE';

export function addStatusBarMessage(message: IStatusBarMessage) {
    return {
        type: ADD_STATUSBAR_MESSAGE,
        msg: message
    };
}

export function updateStatusBarMessage(message: IStatusBarMessage) {
    return {
        type: UPDATE_STATUSBAR_MESSAGE,
        msg: message
    };
}

export function removeStatusBarMessage(messageKey: string) {
    return {
        type: REMOVE_STATUSBAR_MESSAGE,
        key: messageKey
    };
}

export function clearAllStatusBarMessages() {
    return {
        type: CLEAR_ALL_STATUSBAR_MESSAGES
    };
}

export function showStatusBarBusyLine(show: boolean, key: string) {
    return {
        type: SHOW_STATUSBAR_BUSY_LINE,
        show,
        key
    };
}
