import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { getNewComponentId } from '@myia/ngx-core';
import { LocalizationService } from '@myia/ngx-localization';
import { ToastManager } from '@myia/ngx-toast';

const multiSelectionLimitReachedToastKey = 'multiSelectionLimitReachedToastKey';

@Component({
    selector: 'input-multi-selection',
    styleUrls: ['./inputMultiSelection.component.scss'],
    template: `
        <div [ngClass]="{modified: isModified, readOnly: readonly, invalid: control?.invalid, dirty: control?.dirty}">
            <label *ngIf="label">{{label}}</label>
            <div class="checkBoxesBlock">
                <input-checkbox *ngFor="let choice of items; trackBy: trackItem" classNames="classic"
                                [textStates]="undefined" [label]="choice[itemTitlePath] | toString"
                                [value]="choice | inputMultiSelectionItem: value" [isDisabled]="disabled || readonly"
                                [canValueChange]="canValueChange(choice)"
                                (valueChange)="valueChanged($event, choice)"></input-checkbox>
            </div>
        </div>
    `
})
export class InputMultiSelectionComponent<TValue extends Object, TItem> implements OnInit, OnDestroy {
    @Input() label?: string;
    @Input() items?: ReadonlyArray<TItem>;
    @Input() displayType?: string;
    @Input() maxSelected?: number;
    @Output() valueChange = new EventEmitter<Array<TItem>>();
    @Input() disabled = false;
    @Input() validator: any;
    @Input() readonly = false;
    @Input() isRequired = false;
    @Input() isModified = false;
    @Input() itemTitlePath = 'label' as keyof TItem;
    @Input() formGroupRef?: FormGroup;
    @Input() fieldName?: string | number;
    @HostBinding('class') hostClasses?: string;

    id?: string;
    control?: FormControl;

    private _value?: Array<TItem>;
    private _classNames?: string;
    private _controlName?: string;

    @Input() set value(val: Array<TItem> | undefined) {
        if (this._value !== val) {
            this._value = val || [];
            if (this.control) {
                this.control.setValue(val);
            }
            //console.log('MultiSelection value changed: ' + (val ? JSON.stringify(val) : val));
        }
    }

    get value(): Array<TItem> | undefined {
        return this._value;
    }

    @Input() set classNames(value: string) {
        this._classNames = value;
        this.updateHostClasses();
    }

    constructor(private _toastManager: ToastManager, private _localizationService: LocalizationService) {
        this.id = getNewComponentId();
    }

    ngOnInit(): void {
        this.updateHostClasses();
        const validators = this.validator ? [this.validator] : [];
        if (this.isRequired) {
            validators.push(Validators.required);
        }
        this.control = new FormControl({value: this._value, disabled: this.disabled}, Validators.compose(validators));

        if (this.formGroupRef) {
            this._controlName = this.fieldName?.toString() || getNewComponentId();
            this.formGroupRef.addControl(this._controlName, this.control);
        }
    }

    ngOnDestroy() {
        if (this.formGroupRef && this._controlName) {
            this.formGroupRef.removeControl(this._controlName);
        }
    }

    setValue(newValue: Array<TItem>) {
        if (this._value !== newValue) {
            this._value = newValue;
            if (this.control) {
                this.control.setValue(newValue);
                this.control.markAsTouched();
            }
            this.valueChange.emit(newValue);
            //console.log('MultiSelection value changed (internal): ' + (newValue? JSON.stringify(newValue) : newValue));
        }
    }

    canValueChange(item: TItem) {
        return (newValue: boolean, clicked: boolean) => {
            const canChange = !newValue || this.isItemSelected(item) || !this.maxSelected || (this.value?.length ?? 0) < this.maxSelected;
            if (!canChange && clicked) {
                this._toastManager.warning(this._localizationService.translate('MultiSelection.Selection_Max_Limit_Reached', {cnt: this.maxSelected}), {toastKey: multiSelectionLimitReachedToastKey});
            }
            return canChange;
        };
    }

    valueChanged(newValue: boolean, item: TItem) {
        if (newValue && this.maxSelected && this.value && this.value.length >= this.maxSelected) {
            return;
        }
        const value = this.value ? [...this.value] : [];
        if (newValue) {
            value.push(item);
        } else {
            const itemIndex = value.indexOf(item);
            if (itemIndex >= 0) {
                value.splice(itemIndex, 1);
            } else {
                return;
            }
        }
        this.setValue(value);
    }

    trackItem(index: number, item: TItem): any {
        return item['value' as keyof TItem];
    }

    private isItemSelected(choice: TItem): boolean {
        return Boolean(this.value && this.value.indexOf(choice) > -1);
    }

    private updateHostClasses() {
        this.hostClasses = ('multiselect ' + (this._classNames + ' ' || '') + (this.displayType ? this.displayType + ' ' : '')).trim();
    }
}
