import { AfterViewChecked, Directive, ElementRef, Input, OnChanges, OnDestroy } from '@angular/core';
import { NgChanges } from '@myia/ngx-core';
import { AutoSizeHelper } from '../helpers/autoSizeHelper';

@Directive({
    selector: '[autoSize]',
    exportAs: 'autoSizeDirective' //the name of the variable to access the directive using ViewChild()
})
export class AutoSizeDirective implements OnDestroy, AfterViewChecked, OnChanges {

    @Input() autoSize = false;

    private _autoSized = false;

    constructor(private _element: ElementRef) {
    }

    update() {
        if (this._autoSized) {
            AutoSizeHelper.update(this._element.nativeElement);
        }
    }

    ngOnChanges(changes: NgChanges<AutoSizeDirective>): void {
        // check change of 'id' to be able to detect change of states with same streamUrl
        if (changes.autoSize) {
            const chng = changes.autoSize;
            const autoSize = chng.currentValue;
            if (autoSize) {
                this.create();
            } else {
                this.destroy();
            }
        }
    }

    ngAfterViewChecked(): any {
        if (this._autoSized) {
            setTimeout(() => {
                this.update();
            });
        }
    }

    ngOnDestroy() {
        if (this._autoSized) {
            this.destroy();
        }
    }

    private create() {
        AutoSizeHelper.applyToElement(this._element.nativeElement);
        this._autoSized = true;
    }

    private destroy() {
        AutoSizeHelper.destroy(this._element.nativeElement);
        this._autoSized = false;
    }
}
