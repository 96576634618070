import { AppMode } from './appMode';

export interface IAppConfig {
    appMode: AppMode;
    title: string;
    subTitle: string;
    host: string;
    port: number;
    ENV: string;
    reduxStorageKey: string;
    cultures: string;
    defaultCulture: string;
    showDevTools: boolean;
    mobileDeviceId: string;
    webLoginKey: string;
    logLevel: string;
    baseUrl: string;
    BASE_API_URL: string;
    PWA: boolean;
    messagePhotoMaxWidth: number;
    messagePhotoMaxHeight: number;
    messagePhotoAspectRatio: number;
    messagePhotoMinWidth: number;
    isProd: boolean;
}



export const AppConfig = {
    appMode: AppMode.myiaMy,
    isProd: !!window?.process?.env?.['ISPROD'] ?? false,
    ...process.env['MYIA_CONFIG'] as any
} as IAppConfig;
