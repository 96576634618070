import { animate, animation, style } from '@angular/animations';

export const revealAnimation = animation([
        style({
            transform: 'translate({{ transFrom }})',
        }),
        animate('{{ time }} {{ delay }} ease',
            style({
                    transform: 'translate({{ transTo }})',
                }
            )
        ),
    ],
    {params: {transFrom: '100%,0', transTo: '0,0', delay: '0.3s', time: '0.3s'}}
);
