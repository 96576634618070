import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'inputMultiSelectionItem'
})
export class InputMultiSelectionItemPipe<TValue> implements PipeTransform {
    transform(choice: TValue, values: Array<TValue>) {
        return values && values.indexOf && values.indexOf(choice) > -1;
    }
}
