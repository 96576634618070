import { createPersistedState, IPersistedState, rehydrateReducerState, updateState } from '@myia/ngx-redux';
import { REHYDRATE } from 'redux-persist';

import { SET_APP_CULTURE } from './cultureActions';

export const cultureReducerKey = 'culture';

export interface ICultureState extends IPersistedState {
    culture: string | undefined;
}

const initialState = createPersistedState<ICultureState>(
    {
        culture: undefined
    });

function cultureReducer(state = initialState, action: any) {
    switch (action.type) {
        case REHYDRATE:
            return rehydrateReducerState(cultureReducerKey, state, action);
        case SET_APP_CULTURE:
            return updateState(state, (newState: ICultureState) => {
                newState.culture = action.culture;
            });
        default:
            return state;
    }
}

export const cultureReducers = {
    [cultureReducerKey]: cultureReducer
};

