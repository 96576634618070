export const EventRoutePath = 'event';
export const DashboardRoutePath = 'dashboard';
export const LoginRoutePath = 'login';
export const LoginQRRoutePath = 'login-qr';
export const LogoutRoutePath = 'logout';
export const AccessDeniedPath = 'accessDenied';
export const LiveStreamPath = 'stream';
export const NotFoundRoutePath = 'notFound';
export const ModeratorSessionsRoutePath = 'sessions';
export const Customers = {
  Pref2019: {
      LoginRoutePath: 'pref'
  }
};
