import { Component, ViewChild, ElementRef, HostListener } from '@angular/core';
import { SplitterComponent } from './splitter.component';
import { SplitterPositionService } from './splitterPositionService';
import { whenTransitionEnds } from '@myia/ngx-core';

@Component({
    selector: 'vertical-splitter',
    styleUrls: ['./verticalSplitter.component.scss'],
    template: `
        <div
            #primaryComponent
            [hidden]="primaryToggledOff"
            class="left splitter-com">
            <ng-content select=".splitter-content-primary"></ng-content>
        </div>
        <splitter-separator
            [horizontal]="false"
            #separator
            [hidden]="primaryToggledOff || secondaryToggledOff"
            [thickness]="separatorThickness"
            (notifyWillChangeSize)="notifyWillChangeSize($event)">
        </splitter-separator>
        <div
            #secondaryComponent
            [hidden]="secondaryToggledOff"
            class="right splitter-com">
            <ng-content select=".splitter-content-secondary"></ng-content>
        </div>
    `,
})
export class VerticalSplitterComponent extends SplitterComponent {

    @ViewChild('outer', {static: true}) outerContainer?: ElementRef;

    override getPrimarySize(): number | undefined {
        return this.primaryComponent?.nativeElement.offsetWidth;
    }

    override getSecondarySize(): number | undefined {
        return this.secondaryComponent?.nativeElement.offsetWidth;
    }

    override dividerPosition(size: number, animate: boolean): void {
        if (animate) {
            if (this.self.nativeElement) {
                this.self.nativeElement.classList.add('animating');
            }
            whenTransitionEnds(this.primaryComponent?.nativeElement).then(() => {
                this.self.nativeElement.classList.remove('animating');
            });
        }
        const sizePct = (size / this.self.nativeElement[this.sizePropertyName]) * 100;
        this.primaryComponent!.nativeElement.style.width = sizePct + '%';
        this.secondaryComponent!.nativeElement.style.width =
            'calc(' + (100 - sizePct) + '% - ' +
            (this.primaryToggledOff || this.secondaryToggledOff ? 0 : this.separatorThickness) + 'px)';
    }

    @HostListener('mousemove', ['$event'])
    onMousemove(event: MouseEvent): void {
        if (this.isResizing) {
            const coords = SplitterPositionService.offset(this.primaryComponent!);
            this.applySizeChange(event.pageX - coords.left);
        }
    }
}
