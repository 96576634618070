import { Component, HostListener, EventEmitter, Input, Output, HostBinding } from '@angular/core';

@Component({
    selector: 'splitter-separator',
    styleUrls: ['separator.component.scss'],
    template: `
        <div class="handle"></div>
    `
})
export class SeparatorComponent {
    @Input() thickness?: number;
    @Input() @HostBinding('class.horizontal') horizontal = true;
    @Input() @HostBinding('class.splitSeparator') sep = true;
    @Output()
    notifyWillChangeSize: EventEmitter<boolean> = new EventEmitter<boolean>();

    @HostBinding('class.vertical')
    public get vertical(): boolean {
        return !this.horizontal;
    }

    @HostBinding('style.width')
    public get width(): string {
        if (this.horizontal) {
            return `inherit`;
        } else {
            return `${this.thickness}px`;
        }
    }

    @HostBinding('style.height')
    public get height(): string {
        if (this.vertical) {
            return `inherit`;
        } else {
            return `${this.thickness}px`;
        }
    }

    @HostListener('mousedown', ['$event'])
    onMousedown(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.notifyWillChangeSize.emit(true);
    }
}
