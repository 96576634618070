import { Component, Input, ChangeDetectionStrategy, HostBinding } from '@angular/core';

@Component({
    selector: 'calendar-icon',
    styleUrls: ['./calendarIcon.component.scss'],
    template: `
               <time class="icon">
                  <div class="dayBlock">
                    <span class="day">{{date|formatDate:'D'| async }}</span>
                    <span class="dayWeek">{{date|formatDate:'dddd'| async }}</span>
                  </div>
                  <div class="month">{{date|formatDate:(showYear?'MMM':'MMMM')| async }}<span *ngIf="showYear" class="year"> - {{date|formatDate:'YYYY'| async }}</span></div>
                </time>
              `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarIconComponent {
    @Input() date?: Date | string;
    @Input() showYear = false;

    @HostBinding('class') hostClasses = 'calendarIcon';
}
